import https from "./https";
import store from "@/store";

const staffActive = {
  async getInfo() {
    const organization = store.state.liffGeneral.orgCode;
    let response = await https.get(`${organization}/liff/staff/info`);
    return response.data.data;
  },

  verifyPhone(payload) {
    const organization = store.state.liffGeneral.orgCode;
    return https.post(`${organization}/liff/staff/phone/verify`, payload);
  },

  resendPhone(payload) {
    const organization = store.state.liffGeneral.orgCode;
    return https.post(`${organization}/liff/staff/phone/resend`, payload);
  },
};

export default staffActive;
