<template>
  <div>
    <div v-if="showLoading"><Loading /></div>
    <div class="page">
      <div class="page__title">
        {{ getModuleConfig("active_success.title") }}
      </div>
      <div class="page__desc">
        {{ getModuleConfig("active_success.sub_title") }}
      </div>
      <b-card class="mt-3" :img-src="getAvatar" img-alt="Image" img-top>
        <div v-for="info in showInfos" :key="info.matched_key">
          <span v-if="info.display && getStaffInfo(info.matched_key)">
            <div>{{ getStaffInfo(info.matched_key) }}</div>
          </span>
        </div>
      </b-card>
    </div>
    <div class="page__button">
      <SharedButton
        class="s-btn-bg-primary"
        type="button"
        @click="handleSubmit"
      >
        {{ getModuleConfig("active_success.button_text") }}
      </SharedButton>
    </div>
  </div>
</template>

<script>
import Loading from "@/components/Page/Liff/Shared/Loading";
import SharedButton from "@/components/Page/Liff/Shared/Button";
import staffActiveApi from "@/apis/liff/v2/staffActive";
import liff from "@line/liff";
const MODULE_CODE = "staff_active";

export default {
  components: {
    Loading,
    SharedButton,
  },

  data() {
    return {
      showLoading: false,
      showInfos: [],
      staffInfo: {
        // id: "w3d9npke2pn6",
        // name: "西蒙",
        // employee_code: "cc614",
        // avatar_url: "https://profile.line-scdn.net/0hcXlELn5APHdBPCnVq55CCDFsPx1iTWVlZQgjQyZpMkIpDX4laF4hEyZvMUJ6Diwla116FyA4axJNL0sRX2rAQ0YMYkB4Cn4mb1t1lw",
        // service_unit: "02 位名稱",
        // email: "xing1615@gmail.com",
        // mobile_phone: "0917075299",
      },
    };
  },

  async mounted() {
    await this.init();
  },

  computed: {
    getAvatar() {
      return this.staffInfo && this.staffInfo.avatar_url;
    },
  },

  methods: {
    async init() {
      const data = await staffActiveApi.getInfo();
      this.showInfos = this.getModuleConfig("active_success.info")
        .sort(function (a, b) {
          if (a.order > b.order) {
            return 1;
          }
          if (a.order < b.order) {
            return -1;
          }
          // a order must be equal to b
          return 0;
        })
        .filter((info) => info.type !== "img");
      this.staffInfo = data.staff;
    },

    handleSubmit() {
      switch (this.getModuleConfig("active_success.button_action")) {
        case "OA":
          window.location.href = `${process.env.VUE_APP_API_BASE_URL}/${this.$route.params.orgCode}/liff/general/redirect-to-oa`;
          break;
        case "URL":
          window.location.href = this.getModuleConfig(
            "active_success.button_url"
          );
          break;
        case "CLOSE":
        default:
          // 只在 liff app 有用
          liff.closeWindow();
          break;
      }
    },
    getModuleConfig(key) {
      return this.$store.getters["liffModule/getConfig"](MODULE_CODE, key);
    },
    getStaffInfo(code) {
      return this.staffInfo[code] || null;
    },
  },
};
</script>

<style lang="scss" scoped>
.form__error-msg {
  color: #fe0000;
  font-size: 12px;
  margin-top: 8px;
  margin-left: calc((100% - 60px * 5 - 8px * 4) / 2);
}
.page__bottom {
  font-size: 14px;
  line-height: 22px;
  color: var(--liff-secondary_text_color);
  text-align: center;
  span {
    text-decoration: underline;
    cursor: pointer;
  }
}

::v-deep .card {
  width: 250px;
  margin: auto;
}
</style>
