<template>
  <div>
    <loading v-if="displayLoading"></loading>

    <div class="page">
      <div class="page__title">編輯資料</div>
      <div class="page__desc">請填寫您的資料</div>

      <b-card
        :img-src="moduleConfig.avatar.doesShow ? avatarUrl : null"
        img-alt="Image"
        img-top
      >
        <div
          v-if="moduleConfig.avatar.doesShow"
          class="d-flex justify-content-between"
        >
          <SharedButton
            v-if="moduleConfig.avatar.upload_methods_from_line"
            class="s-btn-outline-primary"
            @click="useLineAvatar"
          >
            使用LINE頭像
          </SharedButton>
          <SharedButton
            v-if="moduleConfig.avatar.upload_methods_from_device"
            class="s-btn-outline-primary ml-2"
            @click="openFileInput"
          >
            <span>上傳照片</span>
          </SharedButton>
        </div>

        <div class="mt-2">
          <validation-error-alert
            :errors="validationErrors"
            v-if="validationErrors"
          ></validation-error-alert>
        </div>

        <div class="row mt-2">
          <div
            v-for="(field, index) in fields.filter((x) => {
              return x.hidden == undefined || !x.hidden;
            })"
            :key="`field.${index}`"
            :class="field.class"
          >
            <template v-if="field.type === 'text' && field.matched_key">
              <div class="field">
                <label class="field__label">{{ field.title }}</label>
                <input
                  type="text"
                  class="field__input"
                  autocomplete="off"
                  v-model="staff[field.matched_key]"
                  :placeholder="field.title"
                  :disabled="field.disabled"
                />
              </div>
            </template>
          </div>
        </div>
      </b-card>
    </div>

    <div class="page__button">
      <SharedButton
        size="lg"
        variant="outline-dark"
        class="s-btn-outline-primary"
        @click="cancel"
        :disabled="buttonLoading"
        block
      >
        取消
      </SharedButton>
      <SharedButton
        size="lg"
        variant="primary"
        class="s-btn-bg-primary"
        @click="submit"
        :disabled="buttonLoading"
        block
      >
        送出
      </SharedButton>
    </div>

    <ImageSelector
      ref="ImageSelector"
      @input="selectImage"
      :liff-style="true"
      :cropped-canvas-options="{ width: 1400, height: 1400 }"
    >
      <template #trigger>
        <div></div>
      </template>
    </ImageSelector>
  </div>
</template>

<script>
import LiffChecker from "@/utils/LiffChecker";
import axios from "axios";
import liff from "@line/liff";
import ImageSelector from "@/components/ImageSelector";
import SharedButton from "@/components/Page/Liff/Shared/Button";
import _ from "lodash";

export default {
  components: {
    ImageSelector,
    SharedButton,
  },
  data() {
    return {
      validationErrors: null,
      displayLoading: true,
      buttonLoading: false,
      lineData: null,
      image: null,
      moduleConfig: {
        avatar: {
          doesShow: false,
          upload_methods_from_line: false,
          upload_methods_from_device: false,
        },
        fields: [],
      },
      fields: [],
      staff: {
        name: null,
        employee_code: null,
        service_unit: null,
        job_level: null,
        mobile_phone: null,
        email: null,
        avatar_url: null,
        line_contact_url: null,
      },
    };
  },
  mounted() {
    this.init();
  },
  computed: {
    avatarUrl() {
      return (
        this.staff.avatar_url ??
        "https://via.placeholder.com/500x500.png?text=Empty"
      );
    },
  },
  methods: {
    init() {
      this.fetchMudle();
      this.fetchStaff();
    },
    async fetchMudle() {
      const url =
        process.env.VUE_APP_API_BASE_URL +
        "/" +
        this.$route.params.orgCode +
        "/liff/edit_staff_module";

      await axios
        .get(url)
        .then((response) => {
          this.moduleConfig = response.data.data.module_config;

          this.fields = _.sortBy(this.moduleConfig.fields, "order");
        })
        .catch((error) => {
          this.fetchErrorHandle(error);
        });
    },
    fetchErrorHandle(error) {
      alert("無法正常取得資料，請確認透過正確管道進入此頁面");
      console.error(error);
      liff.closeWindow();
    },
    async fetchStaff() {
      let _this = this;

      const checker = new LiffChecker(this.$route.params.orgCode);
      _this.lineData = await checker.getLineData();

      const url =
        process.env.VUE_APP_API_BASE_URL +
        "/general/staffs/?line_id=" +
        _this.lineData.profile.userId;
      await axios
        .get(url)
        .then((response) => {
          if (response.data.data) {
            this.staff = response.data.data;
          } else {
            throw response.data;
          }
          _this.displayLoading = false;
        })
        .catch((error) => {
          this.fetchErrorHandle(error);
        });
    },
    async submit() {
      let _this = this;
      this.buttonLoading = true;

      let staffData = _this.staff;
      staffData.line_access_token = _this.lineData.accessToken;

      const url =
        process.env.VUE_APP_API_BASE_URL +
        "/" +
        _this.$route.params.orgCode +
        "/liff/edit_staff";

      await axios
        .post(url, staffData)
        .then((response) => {
          if (response.data.status === "success") {
            this.$swal("更新成功", "", "success");
            return;
          }
          if (response.data.status === "failed") {
            this.$swal("更新失敗", response.data.message ?? "", "danger");
            return;
          }
          this.$swal("錯誤", "", "danger");
        })
        .catch((error) => {
          if (error.response.status === 422) {
            this.validationErrors = error.response.data.message;
          } else {
            this.$swal("錯誤", "", "danger");
          }
        })
        .finally(() => {
          _this.buttonLoading = false;
        });
    },
    cancel() {
      liff.closeWindow();
    },
    openFileInput() {
      this.$refs.ImageSelector.openFileSelector();
    },
    async selectImage() {
      const url = await this.uploadImage();
      this.updateAvatar(url);
    },
    async uploadImage() {
      try {
        const url = await this.$refs.ImageSelector.uploadImage();
        return url;
      } catch (error) {
        console.error(error);
        this.$swal.fire({
          title: `上傳圖片失敗：${error.response.data.message}`,
          type: "error",
        });
      }
    },
    async useLineAvatar() {
      if (!this.lineData.profile.pictureUrl) {
        this.$swal(
          "失敗",
          "無法正確讀取到 LINE 照片，請改用上傳圖片",
          "warning"
        );
        return;
      }

      await this.updateAvatar(this.lineData.profile.pictureUrl);
    },
    async updateAvatar(avatarUrl) {
      const url =
        process.env.VUE_APP_API_BASE_URL +
        `/${this.$route.params.orgCode}/liff/update_avatar`;

      try {
        let response = await axios.post(url, {
          avatar_url: avatarUrl,
          id: this.staff.id,
          line_access_token: this.lineData.accessToken,
        });
        this.staff.avatar_url = avatarUrl;

        if (response.data.status === "success") {
          this.$swal("更新成功", "", "success");
          return;
        }
      } catch (error) {
        console.error(error);
        this.$swal("錯誤", "更新頭像失敗", "danger");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.col-6:nth-of-type(odd) .field {
  margin-right: 4px;
}
.col-6:nth-of-type(even) .field {
  margin-left: 4px;
}

.field {
  margin-bottom: 16px;
}

::v-deep .card img {
  width: 250px;
  margin: 20px auto 0;
}
</style>
